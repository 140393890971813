import React, { ReactElement, useMemo, useEffect, useState } from 'react'
import { useMutation, useQueryClient } from 'react-query'
import { useParams } from 'react-router-dom'
import {
  SelectedElementProblemsList,
  TensileTransmitterList,
  VerticalTransmitterList,
} from '@structuralPlanningComponents'
import { AxiosError } from 'axios'
import { filter, find, sortBy } from 'lodash-es'
import { useSnackbar } from 'notistack'
import { TabPanel, TabContext, TabList } from '@mui/lab'
import { Stack, Tab, Typography } from '@mui/material'
import { useCameraStore } from '@modugen/scene/lib/controllers/CameraController/cameraStore'
import { DeleteButton } from '@ui/actions'
import { Box } from '@ui/structure'
import { useModelStore, useStructuralPlanningStore } from '@editorStores'
import {
  useBlockScene,
  useElementLabel,
  useElementType,
  useResultsInvalidation,
} from '@editorHooks'
import { useStructuralPlanningQueryParams } from '@structuralPlanningHooks'
import {
  getVerticalTransmissionGraph,
  getAssemblyAssignment,
  getModel,
  getElementCrossSectionAssignment,
} from '@queries'
import {
  createColumn,
  deleteColumn,
  recalculateTensileTargets,
  updateColumn as updateColumnReq,
} from '@mutations'
import { buildErrorMessage } from 'src/constants'
import { useElementHistory } from 'src/hooks'
import { getElementLabels } from 'src/state/queries/labels'
import SingleElementCSForm from '../../../../../../components/SingleElementCSForm'
import { useSaveTensileTransmissionGraph } from '../../../../hooks/useSaveTensileTransmissionGraph'
import GeometryForm from './GeometryForm'

interface Props {
  selectedElement: string
  geometryEditable?: boolean
  activeElement?: string
}

const ColumnForm = ({
  geometryEditable = true,
  selectedElement,
  activeElement,
}: Props): ReactElement | null => {
  const elementType = useElementType(selectedElement)

  const isOrthographic = useCameraStore(state => state.isOrthographic)
  const [activeTab, setActiveTab] = useState(isOrthographic ? 'geometry' : 'vertical-transmission')

  const { columns, purlins } = useModelStore(state => state.model)
  const removeColumn = useModelStore(state => state.removeColumn)
  const updateColumn = useModelStore(state => state.updateColumn)
  const setTypeVisibility = useModelStore(state => state.setTypeVisibility)

  const { enqueueSnackbar } = useSnackbar()
  const { projectId }: { projectId?: string } = useParams()
  const client = useQueryClient()

  const graph = useStructuralPlanningStore(state => state.tensileTransmissionGraph)
  const setTensileTransmissionGraph = useStructuralPlanningStore(
    state => state.setTensileTransmissionGraph,
  )

  const {
    modes: { isDrawMode },
    params: { verticalTransmitter },
    actions: { setVerticalTransmitter },
  } = useStructuralPlanningQueryParams()

  const invalidateResults = useResultsInvalidation()

  const getLabel = useElementLabel()

  const { mutateAsync, isLoading } = useMutation(
    (column: ShapeObjectLine) => createColumn.request(projectId, column),
    {
      onSuccess: async (data: ShapeObjectLine) => {
        setColumnStale(data)
        await Promise.all([
          client.invalidateQueries(getModel.getKey(projectId)),
          client.invalidateQueries(getVerticalTransmissionGraph.getKey(projectId)),
          client.invalidateQueries(getAssemblyAssignment.getKey(projectId)),
          client.invalidateQueries(getElementLabels.getKey(projectId)),
          client.invalidateQueries(getElementCrossSectionAssignment.getKey(projectId)),
        ])

        invalidateResults(projectId as string)
        enqueueSnackbar('Stütze erfolgreich gespeichert', { variant: 'success' })
      },
      onError: (error: AxiosError) => {
        enqueueSnackbar(buildErrorMessage(error, 'Fehler beim Speichern des Stütze'), {
          variant: 'error',
        })
      },
    },
  )

  const { mutateAsync: handleUpdate, isLoading: isUpdateing } = useMutation(
    (column: ShapeObjectLine) => updateColumnReq.request(projectId, column),
    {
      onSuccess: async (data: ShapeObjectLine) => {
        setColumnStale(data)
        await Promise.all([
          client.invalidateQueries(getModel.getKey(projectId)),
          client.invalidateQueries(getVerticalTransmissionGraph.getKey(projectId)),
          client.invalidateQueries(getAssemblyAssignment.getKey(projectId)),
        ])
        invalidateResults(projectId as string)
        enqueueSnackbar('Stütze erfolgreich gespeichert', { variant: 'success' })
      },
      onError: (error: AxiosError) => {
        enqueueSnackbar(buildErrorMessage(error, 'Fehler beim Speichern der Stütze'), {
          variant: 'error',
        })
      },
    },
  )

  const { mutateAsync: handleDelete, isLoading: isDeleting } = useMutation(
    (columnGuid: string) => deleteColumn.request(projectId, columnGuid),
    {
      onSuccess: async () => {
        await Promise.all([
          client.invalidateQueries(getModel.getKey(projectId)),
          client.invalidateQueries(getVerticalTransmissionGraph.getKey(projectId)),
          client.invalidateQueries(getAssemblyAssignment.getKey(projectId)),
        ])
        invalidateResults(projectId as string)
        enqueueSnackbar('Stütze erfolgreich gelöscht', { variant: 'success' })
      },
      onError: (error: AxiosError) => {
        enqueueSnackbar(buildErrorMessage(error, 'Fehler beim Löschen der Stütze'), {
          variant: 'error',
        })
      },
    },
  )

  useBlockScene(isLoading || isDeleting || isUpdateing)

  const column = useMemo(() => {
    return find(columns, ['guid', selectedElement]) as ShapeObjectLine
  }, [columns, selectedElement])

  const purlin = useMemo(() => find(purlins, ['guid', activeElement]), [activeElement, purlins])

  const { setStale: setColumnStale } = useElementHistory({
    element: column,
    getCurrentElement: () => {
      const columns = useModelStore.getState().model.columns
      return find(columns, ['guid', selectedElement]) as ShapeObjectLine
    },
    removeElement: column => removeColumn(column.guid),
    resetElement: column => updateColumn(column),
    dependencies: [selectedElement],
  })

  useEffect(() => {
    setTypeVisibility('columns' as ElementTypes, true)
  }, [])

  const { mutate: saveGraph, isLoading: isSavingGraph } = useSaveTensileTransmissionGraph(
    projectId as string,
  )

  const { mutate: recalculateTargets, isLoading: isRecalculating } = useMutation(
    () => recalculateTensileTargets.request(projectId, selectedElement?.[0] as string),
    {
      onSuccess: async data => {
        setTensileTransmissionGraph(data)
        invalidateResults(projectId as string)
        enqueueSnackbar('Ziele erfolgreich neu berechnet', { variant: 'success' })
      },
      onError: (error: AxiosError) => {
        enqueueSnackbar(buildErrorMessage(error, 'Fehler beim der Berechnung der Ziele'), {
          variant: 'error',
        })
      },
    },
  )

  const supports = useMemo(() => {
    const items = graph?.element_supports || []
    const filteredItems = filter(items, ['element_guid', selectedElement])
    return sortBy(filteredItems, 'relative_position')
  }, [selectedElement, graph])

  if (!column) return null

  if (column.is_local) {
    return (
      <GeometryForm
        column={column}
        purlin={purlin}
        handleSubmit={() => mutateAsync(column)}
        handleDelete={() => removeColumn(column.guid)}
      />
    )
  }

  return (
    <Box>
      <TabContext value={activeTab}>
        <Box
          sx={{
            borderBottom: 1,
            borderColor: 'divider',
            '& .MuiTab-root': {
              fontSize: 12,
              paddingX: ({ spacing }) => spacing(1.5),
            },
          }}
        >
          <TabList onChange={(_, value) => setActiveTab(value)} scrollButtons="auto">
            <Tab value="geometry" label="Bauteil" />
            <Tab value="vertical-transmission" label="Vertikale Verbindungen" />
            {supports.length !== 0 && (
              <Tab value="connector" label="Zugverbindungen" data-cy="tab-connector" />
            )}
          </TabList>
        </Box>
        <TabPanel value="connector">
          {supports.length !== 0 && (
            <TensileTransmitterList
              key={`connector-list-${isRecalculating}`}
              supports={supports}
              onSave={() => saveGraph(graph)}
              onRecalculate={() => recalculateTargets()}
              isRecalculating={isRecalculating}
              isLoading={isSavingGraph}
            />
          )}
        </TabPanel>
        <TabPanel value="geometry">
          <Stack direction="column" spacing={2}>
            <Typography variant="h6">{getLabel(selectedElement)}</Typography>

            <SingleElementCSForm
              selectedElement={selectedElement}
              elementType={elementType as ElementTypes}
            />

            {geometryEditable ? (
              <GeometryForm
                column={column}
                purlin={purlin}
                handleSubmit={() => handleUpdate(column)}
                handleDelete={() => handleDelete(column.guid)}
                isDeleting={isDeleting}
              />
            ) : (
              <Box display="flex" width="100%" justifyContent="flex-end">
                <DeleteButton onClick={() => handleDelete(column.guid)} loading={isDeleting}>
                  Löschen
                </DeleteButton>
              </Box>
            )}
          </Stack>
        </TabPanel>
        <TabPanel value="vertical-transmission">
          <VerticalTransmitterList
            selectedElement={selectedElement}
            verticalTransmitter={verticalTransmitter}
            setVerticalTransmitter={(guid, elementGuid) =>
              setVerticalTransmitter(guid, elementGuid, isDrawMode)
            }
          />
          <SelectedElementProblemsList selectedElement={selectedElement} />
        </TabPanel>
      </TabContext>
    </Box>
  )
}

export default ColumnForm

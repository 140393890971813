import React, { ReactElement, useMemo } from 'react'
import { filter } from 'lodash-es'
import { Stack } from '@mui/material'
import { DeleteButton, SaveButton } from '@ui/actions'
import { Form, ErrorField } from '@ui/forms'
import { useEditElementStore, useModelStore } from '@editorStores'
import FormFields from '../FormFields'
import { columnSchema } from '../schema'

interface Props {
  handleSubmit: (column: ShapeObjectLine) => void
  handleDelete: () => void
  column: ShapeObjectLine
  isDeleting?: boolean
  purlin?: ShapeObjectLine
}

const GeometryForm = ({
  column,
  handleSubmit,
  handleDelete,
  isDeleting = false,
  purlin = undefined,
}: Props): ReactElement | null => {
  const activeElement = useEditElementStore(state => state.activeElement)
  const { columns } = useModelStore(state => state.model)

  const columnsInStorey = useMemo(() => {
    return filter(columns, ['storey', column.storey])
  }, [columns, column.storey])

  const defaultValues = useMemo(() => {
    const { start, end } = column.shape

    return {
      guid: column.guid,
      x: start.x,
      y: start.y,
      zStart: start.z,
      zEnd: end.z,
      isIntersecting: false,
      isNotOnPurlin: false,
    }
  }, [activeElement, column])

  return (
    <Form
      key={activeElement}
      onSubmit={() => handleSubmit(column)}
      validationSchema={columnSchema}
      validationContext={{ columns: columnsInStorey, purlin }}
      defaultValues={defaultValues}
      data-cy="column-geometry-form"
    >
      {({ formState: { isSubmitting } }) => (
        <>
          <FormFields />

          <ErrorField name="isIntersecting" />
          <ErrorField name="isNotOnPurlin" />
          <Stack direction="row" justifyContent="end" spacing={1} mt={1}>
            <SaveButton
              type="submit"
              loading={isSubmitting}
              fullWidth
              disabled={isDeleting}
              data-cy="btn-submit"
            >
              Speichern
            </SaveButton>
            <DeleteButton
              onClick={handleDelete}
              size="small"
              fullWidth
              loading={isDeleting}
              disabled={isSubmitting}
            >
              Löschen
            </DeleteButton>
          </Stack>
        </>
      )}
    </Form>
  )
}

export default GeometryForm

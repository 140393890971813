import React, { ReactElement, useMemo, useState } from 'react'
import { useMutation, useQueryClient } from 'react-query'
import { useParams } from 'react-router-dom'
import { SelectedElementProblemsList, TensileTransmitterList } from '@structuralPlanningComponents'
import { AxiosError } from 'axios'
import { filter, sortBy } from 'lodash-es'
import { useSnackbar } from 'notistack'
import { LoadingButton, TabContext, TabList, TabPanel } from '@mui/lab'
import { Stack, Tab } from '@mui/material'
import { DeleteButton } from '@ui/actions'
import { Box } from '@ui/structure'
import { useStructuralPlanningStore } from '@editorStores'
import { useResultsInvalidation, useElementType } from '@editorHooks'
import { SingleElementCSForm } from '@editorComponents'
import { useStructuralPlanningQueryParams } from '@structuralPlanningHooks'
import { getElementCrossSectionAssignment, getModel } from '@queries'
import {
  deleteRip,
  recalculateTensileTargets,
  restoreSelectedPositionCSFromAssembly,
} from '@mutations'
import { buildErrorMessage, errors } from 'src/constants'
import { useSaveTensileTransmissionGraph } from '../../hooks/useSaveTensileTransmissionGraph'
import VerticalTransmitterList from '../VerticalTransmitterList'

interface Props {
  position: string
}

const PositionForm = ({ position }: Props): ReactElement => {
  const { projectId } = useParams()
  const client = useQueryClient()
  const invalidateResults = useResultsInvalidation()
  const { enqueueSnackbar } = useSnackbar()

  const [activeTab, setActiveTab] = useState('vertical-transmission')

  const {
    params: { verticalTransmitter, selectedElements },
    actions: { setVerticalTransmitter },
  } = useStructuralPlanningQueryParams()

  const graph = useStructuralPlanningStore(state => state.tensileTransmissionGraph)
  const setTensileTransmissionGraph = useStructuralPlanningStore(
    state => state.setTensileTransmissionGraph,
  )

  const elementType = useElementType(position)

  const { mutate, isLoading } = useMutation(() => deleteRip.request(projectId, position), {
    onSuccess: async () => {
      await Promise.all([
        client.invalidateQueries(getModel.getKey(projectId)),
        client.invalidateQueries(getElementCrossSectionAssignment.getKey(projectId)),
      ])
      invalidateResults(projectId as string)
      enqueueSnackbar('Wandrippe erfolgreich gelöscht', { variant: 'success' })
    },
    onError: (error: AxiosError) => {
      if (errors[error.response?.data.code]) {
        enqueueSnackbar(errors[error.response?.data.code], { variant: 'error' })
        return
      }
      enqueueSnackbar(
        'Fehler beim Löschen der Wandrippe. Hinweis: es können nur selbst hinzugefügte Rippen gelöscht werden',
        { variant: 'error' },
      )
    },
  })

  const { mutateAsync: restorePositionCS, isLoading: isRestoringPositionCS } = useMutation(
    (element_guid: string) => {
      return restoreSelectedPositionCSFromAssembly.request(projectId as string, [element_guid])
    },
    {
      onSuccess: async () => {
        client.invalidateQueries(getElementCrossSectionAssignment.getKey(projectId))
        enqueueSnackbar('Querschnitte von Rippen erfolgreich aus Aufbau übernommen', {
          variant: 'success',
        })
      },
      onError: (error: AxiosError) => {
        enqueueSnackbar(
          buildErrorMessage(error, 'Fehler beim Speichern der Querschnitts-Zuweisung'),
          { variant: 'error' },
        )
      },
    },
  )

  const { mutate: saveGraph, isLoading: isSavingGraph } = useSaveTensileTransmissionGraph(
    projectId as string,
  )

  const { mutate: recalculateTargets, isLoading: isRecalculating } = useMutation(
    () => recalculateTensileTargets.request(projectId, selectedElements?.[0] as string),
    {
      onSuccess: async data => {
        setTensileTransmissionGraph(data)
        invalidateResults(projectId as string)
        enqueueSnackbar('Ziele erfolgreich neu berechnet', { variant: 'success' })
      },
      onError: (error: AxiosError) => {
        enqueueSnackbar(buildErrorMessage(error, 'Fehler beim der Berechnung der Ziele'), {
          variant: 'error',
        })
      },
    },
  )

  const supports = useMemo(() => {
    const items = graph?.element_supports || []
    const filteredItems = filter(items, ['element_guid', position])
    return sortBy(filteredItems, 'relative_position')
  }, [position, graph])

  return (
    <Box>
      <TabContext value={activeTab}>
        <Box
          sx={{
            borderBottom: 1,
            borderColor: 'divider',
            '& .MuiTab-root': {
              fontSize: 12,
              paddingX: ({ spacing }) => spacing(1.5),
            },
          }}
        >
          <TabList
            onChange={(_, value) => setActiveTab(value)}
            variant="scrollable"
            scrollButtons="auto"
          >
            <Tab value="geometry" label="Bauteil" data-cy="tab-geometry" />
            <Tab value="vertical-transmission" label="Vertikale Verbindungen" />
            {supports.length !== 0 && (
              <Tab value="connector" label="Zugverbindungen" data-cy="tab-connector" />
            )}
          </TabList>
        </Box>
        <TabPanel value="connector">
          {supports.length !== 0 && (
            <TensileTransmitterList
              key={`connector-list-${isRecalculating}`}
              supports={supports}
              onSave={() => saveGraph(graph)}
              onRecalculate={() => recalculateTargets()}
              isRecalculating={isRecalculating}
              isLoading={isSavingGraph}
            />
          )}
        </TabPanel>
        <TabPanel value="vertical-transmission">
          <VerticalTransmitterList
            selectedElement={position}
            verticalTransmitter={verticalTransmitter}
            setVerticalTransmitter={setVerticalTransmitter}
          />
          <SelectedElementProblemsList selectedElement={position} />
        </TabPanel>
        <TabPanel value="geometry">
          <Stack direction="column" spacing={2}>
            <SingleElementCSForm
              selectedElement={position}
              elementType={elementType as ElementTypes}
            />

            <LoadingButton
              loading={isRestoringPositionCS}
              type="submit"
              variant="contained"
              size="small"
              data-cy="restore-rip-cs-btn"
              onClick={() => restorePositionCS(position)}
            >
              Querschnitt aus Aufbau wiederherstellen
            </LoadingButton>

            <Box display="flex" justifyContent="flex-end">
              <DeleteButton onClick={() => mutate()} loading={isLoading} data-cy="btn-delete">
                Löschen
              </DeleteButton>
            </Box>
          </Stack>
        </TabPanel>
      </TabContext>
    </Box>
  )
}

export default PositionForm

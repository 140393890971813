export const slabLikeElementTypes: {
  value: ElementTypes
  label: string
}[] = [
  {
    value: 'slabs',
    label: 'Decken',
  },
  {
    value: 'vertical_slabs',
    label: 'Deckenpositionen',
  },
]

export const foundationLikeElementTypes: {
  value: ElementTypes
  label: string
}[] = [
  {
    value: 'foundation',
    label: 'Fundament',
  },
]

export const wallLikeElementTypes: {
  value: ElementTypes
  label: string
}[] = [
  {
    value: 'outer_walls',
    label: 'Aussenwände',
  },
  {
    value: 'inner_walls',
    label: 'Innenwände',
  },
]

export const roofSlabLikeElementTypes: {
  value: ElementTypes
  label: string
}[] = [
  {
    value: 'roof_slabs',
    label: 'Dächer',
  },
  {
    value: 'vertical_roof_slabs',
    label: 'Dachpositionen',
  },
]

export const lineLikeElementTypes: {
  value: ElementTypes
  label: string
}[] = [
  {
    value: 'purlins',
    label: 'Pfetten',
  },
  {
    value: 'columns',
    label: 'Stützen',
  },
  {
    value: 'beams',
    label: 'Unterzüge',
  },
]

export const elementTypeConfig: {
  value: ElementTypes
  label: string
}[] = [
  ...foundationLikeElementTypes,
  ...wallLikeElementTypes,
  ...slabLikeElementTypes,
  ...roofSlabLikeElementTypes,
  ...lineLikeElementTypes,
]

export const elementTypes: ElementTypes[] = elementTypeConfig.map(({ value }) => value)
export const modelName = 'rendered-model'
export const absoluteTransmitterSnapTolerance = 0.05 // 5cm

const allElementTypesMap: Record<ElementTypes, ElementTypes> = {
  beams: 'beams',
  columns: 'columns',
  inner_walls: 'inner_walls',
  lintels: 'lintels',
  outer_walls: 'outer_walls',
  purlins: 'purlins',
  roof_slabs: 'roof_slabs',
  rips: 'rips',
  slabs: 'slabs',
  vertical_roof_slabs: 'vertical_roof_slabs',
  vertical_slabs: 'vertical_slabs',
  slab_beams: 'slab_beams',
  roof_slab_beams: 'roof_slab_beams',
  standard_rips: 'standard_rips',
  foundation: 'foundation',
}
export const allElementTypes = Object.keys(allElementTypesMap) as ElementTypes[]

export const FOUNDATION_REPLACEMENT_GUID = 'foundation-replacement'

interface ParsedConfigs {
  apiURL: string
  auth0Domain: string
  auth0ClientID: string
  auth0Audience: string
  auth0Scopes: string
  sentryDSN: string
  posthogToken: string | undefined
  posthogApiHost: string | undefined
  environment: string
  ff_cs_suggestions: boolean
  ff_simplified_seismic: boolean
  ff_use_position_results_grid: boolean
  ff_non_wall_tensile: boolean
}

const defaultConfig: ParsedConfigs = {
  apiURL: 'http://localhost:8100',
  auth0Domain: 'dev-diem3oe2.eu.auth0.com',
  auth0ClientID: 'az1QUs1k2bljjifGgWC85GKqy6qZSlGW',
  auth0Audience: 'api.modugen.de',
  auth0Scopes: 'openid access:all email',
  environment: 'development',
  sentryDSN: 'https://15b13dcd3bf3427892863232b9f54d2a@o1193085.ingest.sentry.io/6315246',
  posthogToken: undefined,
  posthogApiHost: undefined,
  ff_cs_suggestions: false,
  ff_simplified_seismic: false,
  ff_use_position_results_grid: false,
  ff_non_wall_tensile: false,
}

interface EnvironmentConfigs {
  apiURL: string | undefined
  auth0Domain: string | undefined
  auth0ClientID: string | undefined
  auth0Audience: string | undefined
  auth0Scopes: string
  sentryDSN: string | undefined
  posthogApiHost: string | undefined
  posthogToken: string | undefined
  environment: string | undefined
  ff_cs_suggestions: boolean | undefined
  ff_simplified_seismic: boolean | undefined
  ff_use_position_results_grid: boolean | undefined
  ff_non_wall_tensile: boolean | undefined
}

function parseBoolean(value: string | undefined): boolean | undefined {
  if (value === undefined) {
    return undefined
  }
  return value.toLowerCase() === 'true'
}

const currentEnvConfig: EnvironmentConfigs = {
  apiURL: import.meta.env.VITE_API_URL,
  auth0Domain: import.meta.env.VITE_AUTH_0_DOMAIN,
  auth0ClientID: import.meta.env.VITE_AUTH_0_CLIENT_ID,
  auth0Audience: import.meta.env.VITE_AUTH_0_AUDIENCE,
  auth0Scopes: import.meta.env.VITE_AUTH_0_SCOPES,
  environment: import.meta.env.VITE_ENVIRONMENT,
  sentryDSN: import.meta.env.VITE_SENTRY_DSN,
  posthogToken: import.meta.env.VITE_POSTHOG_TOKEN,
  posthogApiHost: import.meta.env.VITE_POSTHOG_API,
  ff_cs_suggestions: parseBoolean(import.meta.env.VITE_FF_CS_SUGGESTIONS),
  ff_simplified_seismic: parseBoolean(import.meta.env.VITE_FF_SIMPLIFIED_SEISMIC),
  ff_use_position_results_grid: parseBoolean(import.meta.env.VITE_FF_USE_POSITION_RESULTS_GRID),
  ff_non_wall_tensile: parseBoolean(import.meta.env.VITE_FF_NON_WALL_TENSILE),
}

function mergeConfigs(
  defaultConfig: ParsedConfigs,
  currentEnvConfig: EnvironmentConfigs,
): ParsedConfigs {
  // Merges the defaults and the environment variables,
  // prefering the environment variables if they are defined

  const mergedConfig: ParsedConfigs = { ...defaultConfig }

  for (const key in defaultConfig) {
    const typedKey = key as keyof ParsedConfigs
    if (currentEnvConfig[typedKey] !== undefined) {
      // @ts-ignore
      mergedConfig[typedKey] = currentEnvConfig[typedKey]
    }
  }

  return mergedConfig
}

export const appConfig = mergeConfigs(defaultConfig, currentEnvConfig)

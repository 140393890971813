import React, { ReactElement, useEffect, useRef } from 'react'
import { useParams } from 'react-router-dom'
import CallMerge from '@mui/icons-material/CallMerge'
import FilterAltOutlined from '@mui/icons-material/FilterAltOutlined'
import Grid4x4Icon from '@mui/icons-material/Grid4x4'
import PublicIcon from '@mui/icons-material/Public'
import { Stack } from '@mui/material'
import { ToggleButton, ToggleButtonGroup, ToggleButtonDropDown } from '@ui/actions'
import { Box } from '@ui/structure'
import { useControlStore } from '@editorStores'
import ComponentDetailControls from './ComponentDetailControls'
import DrawControls from './DrawControls'
import SelectionControls from './SelectionControls'
import StoreySelect from './StoreySelect'
import TapelineControls from './TapelineControls'
import VisibilityFilters from './VisibilityFilters'
import VisibilitySelector from './VisibilitySelector'

interface Props {
  selectionControls?: boolean
}

const ControlsLeft = ({ selectionControls = false }: Props): ReactElement => {
  const orthographicDrawMode = useControlStore(state => state.orthographicDrawMode)

  return (
    <Stack direction="row" spacing={1}>
      <TapelineControls />
      <DrawControls />

      <VisibilitySelector />

      {!orthographicDrawMode && selectionControls && <SelectionControls />}

      <ComponentDetailControls />
    </Stack>
  )
}

const ControlsRight = (): ReactElement => {
  const { projectId } = useParams()
  const portalRef = useRef(null)
  const widgetPortalRef = useRef(null)

  const { gridEnabled, toggleGridEnabled, axesEnabled, toggleAxesEnabled, orthographicDrawMode } =
    useControlStore(state => ({
      gridEnabled: state.gridEnabled,
      toggleGridEnabled: state.toggleGridEnabled,
      axesEnabled: state.axesEnabled,
      toggleAxesEnabled: state.toggleAxesEnabled,
      hiddenElementIds: state.hiddenElementIds,
      orthographicDrawMode: state.orthographicDrawMode,
    }))
  const setFilterPortalElement = useControlStore(state => state.setFilterPortalElement)
  const setWidgetPortalElement = useControlStore(state => state.setWidgetPortalElement)

  useEffect(() => {
    if (!portalRef.current) return

    setFilterPortalElement(portalRef.current)
  }, [portalRef.current])

  useEffect(() => {
    if (!widgetPortalRef.current) return

    setWidgetPortalElement(widgetPortalRef.current)
  }, [widgetPortalRef.current])

  return (
    <Stack direction="row" spacing={1}>
      <ToggleButtonGroup>
        <ToggleButton
          hint="Freigabelink kopieren"
          onClick={() => {
            const publicLink = `${window.origin}/projects/${projectId}`
            navigator.clipboard.writeText(publicLink)
          }}
          selected={false}
          Icon={PublicIcon}
        />
      </ToggleButtonGroup>
      {
        <>
          <ToggleButtonGroup>
            <ToggleButton
              hint="Raster anzeigen (r)"
              onClick={toggleGridEnabled}
              selected={gridEnabled}
              Icon={Grid4x4Icon}
            />
            <ToggleButton
              hint="Koordinatensystem anzeigen (d) - x: rot - y: grün"
              onClick={toggleAxesEnabled}
              selected={axesEnabled}
              Icon={CallMerge}
            />
          </ToggleButtonGroup>
        </>
      }
      <Box ref={widgetPortalRef} />
      <Box>
        <ToggleButtonDropDown Icon={FilterAltOutlined} data-cy="hide-dropdown">
          {!orthographicDrawMode && (
            <>
              <StoreySelect />
              <VisibilityFilters />
            </>
          )}
          <Stack direction="column" ref={portalRef} />
        </ToggleButtonDropDown>
      </Box>
    </Stack>
  )
}

export { ControlsLeft, ControlsRight }

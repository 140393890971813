import React, { ReactElement, useEffect, useMemo } from 'react'
import { useHotkeys } from 'react-hotkeys-hook'
import { mapValueKey } from '@editorUtils'
import { TransmitterMesh, StiffeningMesh, LoadMesh, PositionMeshElement } from '@scene'
import { find, reduce, filter, reject } from 'lodash-es'
import { closeSnackbar, enqueueSnackbar } from 'notistack'
import { Stack, Typography } from '@mui/material'
import { useCameraStore } from '@modugen/scene/lib/controllers'
import { useTapelineStore } from '@modugen/scene/lib/controllers/TapelineController/tapelineStore'
import { ThreeEvent } from '@react-three/fiber'
import {
  useStructuralPlanningStore,
  useElementLoadStore,
  useModelStore,
  useEditElementStore,
  useElementSelectionStore,
  useSystemManagerStore,
  roofStoreyKey,
} from '@editorStores'
import {
  useSelectionMode,
  useHiddenElements,
  useTypeInteraction,
  useModelClickListeners,
  useHighlightFromParams,
} from '@editorHooks'
import { useProblemViewColoring } from '@structuralPlanningHooks'
import {
  PurlinDrawer3D,
  AreaLoadDrawer,
  FloorplanDrawer,
  OpeningsDrawer,
  OrthographicWall,
  VerticalRoofSlabDrawer3D,
  WallRipDrawer3D,
  WallLintelDrawer3D,
  ActualRoofDrawing,
} from '../'
import { useFilterState } from '../../hooks/useFilterState'
import useStructuralPlanningQueryParams from '../../hooks/useStructuralPlanningQueryParams'
import BeamDrawer3D from '../BeamDrawing/components/BeamDrawer'
import SlabDrawing from '../RoofDrawing/components/SlabDrawing'
import { VerticalSlabDrawer3D } from '../VerticalSlabs'
import SlabSelection from './SlabSelection'

const StructuralPlanningScene = (): ReactElement => {
  const {
    params: {
      mode,
      selectedElements,
      selectedStiffeningElements,
      connectorGuid,
      verticalTransmitter,
      load: activeLoadGuid,
      drawAreaLoadElement,
      filterState: {
        showStiffening,
        showConnectors,
        showLineLoads,
        showPointLoads,
        showAreaLoads,
        showVerticalTransmitters,
      },
      storey,
    },
    actions: {
      setElements,
      setStiffeningElement,
      setConnector,
      selectLoad: setLoad,
      setVerticalTransmitter,
      toggleMode,
      setDrawAreaLoadElement,
      reset,
    },
    modes: {
      isSelectionMode,
      isWallMode,
      isBeamMode,
      isNeutralMode,
      isLoadMode,
      isVerticalSlabMode,
      isDrawingVerticalRoofMode,
      isDrawingSlabMode,
      isDrawingRoofMode,
      isDrawingPurlinsMode,
      isRoofMode,
      isPurlinMode,
      isColumnMode,
      isStoreySelectionMode,
      isDrawingBeamsMode,
      isDrawingColumnsMode,
      isDrawingWallsMode,
      isDrawingOpeningsMode,
      isRipMode,
      isLintelMode,
      isWallRipMode,
      isWallLintelMode,
      isDrawingVerticalSlabsMode,
    },
  } = useStructuralPlanningQueryParams()
  const showLoads = showPointLoads || showLineLoads || showAreaLoads
  const { showRips, showLintels } = useFilterState()

  // STATE

  const { isSelectionMode: isSceneSelectionMode } = useSelectionMode()
  const proposal = useStructuralPlanningStore(state => state.proposal)
  const mergedProposal = useStructuralPlanningStore(state => state.mergedProposal)
  const tensileTransmissionGraph = useStructuralPlanningStore(
    state => state.tensileTransmissionGraph,
  )
  const problemViewActive = useStructuralPlanningStore(state => state.problemViewActive)
  const verticalTransmissionGraph = useStructuralPlanningStore(
    state => state.verticalTransmissionGraph,
  )
  const hiddenLoads = useStructuralPlanningStore(state => state.hiddenLoads)
  const lintels = useModelStore(state => state.model.lintels)
  const rips = useModelStore(state => state.model.rips)
  const setTypeVisibility = useModelStore(state => state.setTypeVisibility)

  const loads = useElementLoadStore(state => state.loads)
  const addLoad = useElementLoadStore(state => state.addLoadPerElement)

  const hiddenElements = useHiddenElements()

  const model = useModelStore(state => state.model)
  const domains = useModelStore(state => state.domains)

  const toggleSingleStoreyVisibility = useModelStore(state => state.toggleSingleStoreyVisibility)
  const availableStoreys = useModelStore(state => state.availableStoreys)
  const availableNonRoofStoreys = reject(
    [...availableStoreys],
    availStorey => availStorey === roofStoreyKey,
  )
  const isOrthographic = useCameraStore(state => state.isOrthographic)

  const addSelectedIds = useStructuralPlanningStore(state => state.addSelectedIds)
  const deselectId = useStructuralPlanningStore(state => state.deselectId)
  const selectedIds = useStructuralPlanningStore(state => state.selectedIds)

  const activeElement = useEditElementStore(state => state.activeElement)
  const setActiveElement = useEditElementStore(state => state.setActiveElement)
  const activeOpening = useEditElementStore(state => state.activeOpening)
  const setActiveOpening = useEditElementStore(state => state.setActiveOpening)

  const onModelClick = useModelClickListeners()
  const highlightedSecondaryIds = useElementSelectionStore(state => state.highlightedSecondaryIds)

  const { isSelectionMode: isSelectionModeSnack } = useSelectionMode()

  const isTapelineActive = useTapelineStore(state => state.isActive)

  const elementCrossSectionAssignment = useSystemManagerStore(
    state => state.elementCrossSectionAssignment,
  )

  // MEMOS

  const positions = useMemo(() => [...rips, ...lintels], [rips, lintels])

  const drawAreaLoadElementShape = useMemo(
    () =>
      find([...model.vertical_roof_slabs, ...model.vertical_slabs], ['guid', drawAreaLoadElement]),
    [drawAreaLoadElement, model],
  )

  const elementTargetDomains = useMemo(
    () =>
      reduce(
        verticalTransmissionGraph.element_targets,
        (collector, target) => ({
          ...collector,
          [target.domain_guid]: target.domain,
        }),
        {} as Record<string, Domain | null>,
      ),
    [verticalTransmissionGraph],
  )

  const wall = useMemo(() => find(model.walls, ['guid', activeElement]), [model, activeElement])

  const guidToCrossSection = useMemo(
    () => mapValueKey(elementCrossSectionAssignment, 'element_guid'),
    [elementCrossSectionAssignment],
  )

  // ACTIONS

  useTypeInteraction('none', isSelectionMode)
  useModelClickListeners(
    event => {
      const { name: guid } = event.object
      const { elementType } = event.object.userData

      if (mode === 'draw-openings') {
        setActiveElement(guid)
        return
      }

      // select all roofs at once since config is the same
      const isRoof = elementType === 'roof_slabs'
      const elements = isRoof ? model.roof_slabs.map(({ guid }) => guid) : [guid]
      const filteredElements = elements.filter(id => !(selectedElements || []).includes(id))

      setElements(filteredElements, elementType)
    },
    [model, selectedElements, isLoadMode, mode],
    !isSelectionMode &&
      !isWallRipMode &&
      !isLoadMode &&
      !isDrawingVerticalRoofMode &&
      !isDrawingVerticalSlabsMode &&
      !isDrawingSlabMode &&
      !isDrawingRoofMode &&
      !isDrawingBeamsMode &&
      !isDrawingPurlinsMode &&
      !isSelectionModeSnack &&
      !isTapelineActive,
  )

  useHighlightFromParams(['elements'], !selectedStiffeningElements?.length)

  const handleElementClick = (event: ThreeEvent<MouseEvent>) => {
    const { localId, selectable } = event.object.userData

    if (!selectable) return

    if (selectedIds.has(localId)) deselectId(localId)
    else addSelectedIds([localId])
  }

  const handleMergedElementClick = (event: ThreeEvent<MouseEvent>) => {
    const { guid } = event.object.userData

    setStiffeningElement(guid)
  }

  // DERIVED DATA

  const filteredLoads = useMemo(() => {
    return filter(loads, load => {
      if (hiddenLoads.includes(load.guid)) return false
      if (load.load_type === 'point-load' && showPointLoads) return true
      if (load.load_type === 'line-load' && showLineLoads) return true
      if (load.load_type === 'area-load' && showAreaLoads) return true

      return false
    })
  }, [loads, showLineLoads, showPointLoads, showAreaLoads, hiddenLoads])

  const loadsOnSelectedElement = useMemo(() => {
    if (!selectedElements?.[0]) return []

    return filter(loads, load => {
      if (load.element_guid !== selectedElements[0]) return false
      if (hiddenLoads.includes(load.guid)) return false

      return true
    })
  }, [loads, selectedElements, hiddenLoads])

  // EFFECTS

  useEffect(() => {
    if (storey) setActiveElement(null)
  }, [storey])

  useEffect(() => {
    if (isDrawingPurlinsMode) {
      // Hide vertical roof slabs when entering
      setTypeVisibility('vertical_roof_slabs' as ElementTypes, false)
      return () => {
        // unhide vertical roof slabs when leaving
        setTypeVisibility('vertical_roof_slabs' as ElementTypes, true)
      }
    }
  }, [isDrawingPurlinsMode])

  const hotKeys3DSnackbarKey = 'storey-hot-keys-3D'
  useEffect(() => {
    if (!isOrthographic) {
      enqueueSnackbar(
        <>
          <Stack direction="column">
            <Typography>{'Nutze (0, 1, ..., "d") um Stockwerke zu filtern.'}</Typography>
          </Stack>
        </>,
        {
          variant: 'info',
          key: hotKeys3DSnackbarKey,
        },
      )
      return () => {
        closeSnackbar(hotKeys3DSnackbarKey)
      }
    }
  }, [isOrthographic])

  useHotkeys(
    ['0', '1', '2', '3', '4', '5', '6', '7', '8', '9', 'd'],
    event => {
      if (event.key === 'd') {
        toggleSingleStoreyVisibility(roofStoreyKey)
        // Close the snackbar when the user uses the hotkey
        closeSnackbar(hotKeys3DSnackbarKey)
      } else if (availableNonRoofStoreys.includes(event.key)) {
        toggleSingleStoreyVisibility(event.key)
        // Close the snackbar when the user uses the hotkey
        closeSnackbar(hotKeys3DSnackbarKey)
      }
    },
    { enabled: !isOrthographic },
    [availableNonRoofStoreys],
  )

  // COLORING

  useProblemViewColoring(problemViewActive)

  // RENDER

  if (isStoreySelectionMode || (!storey && (isDrawingColumnsMode || isDrawingWallsMode)))
    // When jumping into a 2D only tool, we have to select a storey reference first.
    // Hemce, if these tools are activated, but no storey is selected yet - show the slab selection
    return <SlabSelection />

  if (storey)
    return (
      <FloorplanDrawer
        resetSelectedElement={() => {
          toggleMode(undefined)
          setActiveElement(null)
        }}
        selectedElement={selectedElements?.[0] as string | undefined}
        mode={mode as StructuralPlanningModes | undefined}
      />
    )

  if (isDrawingOpeningsMode)
    return (
      <>
        {mode === 'draw-openings' && activeElement && wall && (
          <>
            <OrthographicWall
              wallGuid={activeElement}
              onClickOpening={setActiveOpening}
              selectedOpenings={activeOpening ? [activeOpening] : undefined}
            />
            <OpeningsDrawer
              key={activeOpening}
              wallGuid={activeElement}
              openingGuid={activeOpening}
            />
          </>
        )}
      </>
    )

  if (isWallRipMode)
    return (
      <>
        {rips.map(rip => (
          <PositionMeshElement
            key={rip.position_guid}
            position_type="rip"
            data={rip}
            height={guidToCrossSection[rip.position_guid]?.element_cs.shape.height}
            width={guidToCrossSection[rip.position_guid]?.element_cs.shape.width}
            onClick={() => setElements([rip.position_guid])}
            visible={rip.position_guid !== selectedElements?.[0]}
          />
        ))}

        <WallRipDrawer3D
          selectRip={rip => setElements([rip])}
          selectedRip={selectedElements?.[0] || undefined}
        />
      </>
    )

  if (isWallLintelMode)
    return (
      <>
        {lintels.map(lintel => (
          <PositionMeshElement
            key={lintel.position_guid}
            position_type="lintel"
            data={lintel}
            height={guidToCrossSection[lintel.position_guid]?.element_cs.shape.height}
            width={guidToCrossSection[lintel.position_guid]?.element_cs.shape.width}
            onClick={() => setElements([lintel.position_guid])}
            visible={lintel.position_guid !== selectedElements?.[0]}
          />
        ))}
        {rips.map(rip => (
          <PositionMeshElement
            key={rip.position_guid}
            position_type="rip"
            data={rip}
            height={guidToCrossSection[rip.position_guid]?.element_cs.shape.height}
            width={guidToCrossSection[rip.position_guid]?.element_cs.shape.width}
            onClick={() => setElements([rip.position_guid])}
            visible={rip.position_guid !== selectedElements?.[0]}
          />
        ))}
        <WallLintelDrawer3D selectedLintel={selectedElements?.[0] || undefined} />
      </>
    )

  if (isDrawingVerticalRoofMode)
    return <VerticalRoofSlabDrawer3D setActiveElement={setActiveElement} resetMode={reset} />

  if (isDrawingVerticalSlabsMode)
    return <VerticalSlabDrawer3D setActiveElement={setActiveElement} resetMode={reset} />

  if (isDrawingSlabMode) return <SlabDrawing key={activeElement} />

  if (isDrawingRoofMode) return <ActualRoofDrawing key={activeElement} />

  if (isDrawingPurlinsMode) return <PurlinDrawer3D reset={reset} />

  if (isDrawingBeamsMode) return <BeamDrawer3D reset={reset} />

  return (
    <>
      {isNeutralMode && (
        <>
          {showVerticalTransmitters && (
            <TransmitterMesh
              data={verticalTransmissionGraph}
              domains={domains}
              transmitterGuid={verticalTransmitter as string}
              onClick={({ guid, element_guid }) => setVerticalTransmitter(guid, element_guid)}
              elementGuids={selectedElements as string[]}
              hiddenElements={hiddenElements}
            />
          )}
          {showStiffening && (
            <StiffeningMesh
              data={mergedProposal}
              hiddenElements={hiddenElements}
              onClick={handleMergedElementClick}
              pointerPropagation
              noPointerInteractions
            />
          )}
          {showConnectors && (
            <TransmitterMesh
              data={tensileTransmissionGraph}
              transmitterGuid={connectorGuid as string}
              onClick={({ guid, element_guid }) => setConnector(guid, element_guid)}
              domains={domains}
              elementGuids={selectedElements as string[]}
              hiddenElements={hiddenElements}
            />
          )}
          {showLoads && (
            <LoadMesh
              loads={filteredLoads}
              domains={domains}
              elementTargetDomains={elementTargetDomains}
              onClick={setLoad}
              activeLoadGuid={activeLoadGuid as string}
              hiddenElements={hiddenElements}
              positions={positions}
            />
          )}

          {rips && (
            <group visible={showRips}>
              {rips.map(rip => (
                <PositionMeshElement
                  key={rip.position_guid}
                  data={rip}
                  height={guidToCrossSection[rip.position_guid]?.element_cs.shape.height}
                  width={guidToCrossSection[rip.position_guid]?.element_cs.shape.width}
                  position_type="rip"
                  onClick={e => onModelClick?.(e)}
                  isTarget={highlightedSecondaryIds.has(rip.position_guid)}
                  visible={!hiddenElements.has(rip.wall_guid)}
                />
              ))}
            </group>
          )}

          {lintels && (
            <group visible={showLintels}>
              {lintels.map(lintel => (
                <PositionMeshElement
                  key={lintel.position_guid}
                  data={lintel}
                  height={guidToCrossSection[lintel.position_guid]?.element_cs.shape.height}
                  width={guidToCrossSection[lintel.position_guid]?.element_cs.shape.width}
                  position_type="lintel"
                  onClick={e => onModelClick?.(e)}
                  isTarget={highlightedSecondaryIds.has(lintel.position_guid)}
                  visible={!hiddenElements.has(lintel.wall_guid)}
                />
              ))}
            </group>
          )}
        </>
      )}

      {isSelectionMode && showStiffening && (
        <StiffeningMesh
          data={proposal}
          hiddenElements={hiddenElements}
          onClick={handleElementClick}
          selectedIds={selectedIds}
        />
      )}

      {(isWallMode ||
        isVerticalSlabMode ||
        isBeamMode ||
        isRoofMode ||
        isPurlinMode ||
        isColumnMode ||
        isRipMode ||
        isLintelMode) && (
        <>
          {showStiffening && (
            <StiffeningMesh
              key={selectedElements?.toString()}
              data={mergedProposal}
              hiddenElements={hiddenElements}
              onClick={isTapelineActive ? undefined : handleMergedElementClick}
              selectedIds={selectedStiffeningElements as string[]}
              elementGuids={selectedElements as string[]}
              pointerPropagation={isSceneSelectionMode}
              noPointerInteractions={isSceneSelectionMode}
              showNonInteractable
            />
          )}

          {showVerticalTransmitters && (
            <TransmitterMesh
              data={verticalTransmissionGraph}
              domains={domains}
              transmitterGuid={verticalTransmitter as string}
              onClick={({ guid, element_guid }) => setVerticalTransmitter(guid, element_guid)}
              elementGuids={selectedElements as string[]}
              hiddenElements={hiddenElements}
            />
          )}
          {showConnectors && (
            <TransmitterMesh
              data={tensileTransmissionGraph}
              transmitterGuid={connectorGuid as string}
              onClick={({ guid, element_guid }) => setConnector(guid, element_guid)}
              domains={domains}
              elementGuids={selectedElements as string[]}
              hiddenElements={hiddenElements}
            />
          )}
          {rips && (
            <group visible={showRips}>
              {rips.map(rip => (
                <PositionMeshElement
                  key={rip.position_guid}
                  data={rip}
                  height={guidToCrossSection[rip.position_guid]?.element_cs.shape.height}
                  width={guidToCrossSection[rip.position_guid]?.element_cs.shape.width}
                  position_type="rip"
                  onClick={e => onModelClick?.(e)}
                  isSelected={rip.position_guid === selectedElements?.[0]}
                  isTarget={highlightedSecondaryIds.has(rip.position_guid)}
                  visible={!hiddenElements.has(rip.wall_guid)}
                />
              ))}
            </group>
          )}

          {lintels && (
            <group visible={showLintels}>
              {lintels.map(lintel => (
                <PositionMeshElement
                  key={lintel.position_guid}
                  data={lintel}
                  height={guidToCrossSection[lintel.position_guid]?.element_cs.shape.height}
                  width={guidToCrossSection[lintel.position_guid]?.element_cs.shape.width}
                  position_type="lintel"
                  onClick={e => onModelClick?.(e)}
                  isSelected={lintel.position_guid === selectedElements?.[0]}
                  isTarget={highlightedSecondaryIds.has(lintel.position_guid)}
                  visible={!hiddenElements.has(lintel.wall_guid)}
                />
              ))}
            </group>
          )}
        </>
      )}
      {isLoadMode && mode === 'area-load' && drawAreaLoadElement && drawAreaLoadElementShape && (
        <AreaLoadDrawer
          onAddLoad={load => {
            addLoad(load)
            setDrawAreaLoadElement(undefined)
            setLoad(load)
          }}
          drawElementGuid={drawAreaLoadElement}
          elementShape={drawAreaLoadElementShape}
        />
      )}

      {isLoadMode && (
        <LoadMesh
          loads={loadsOnSelectedElement}
          domains={domains}
          elementTargetDomains={elementTargetDomains}
          onClick={setLoad}
          activeLoadGuid={activeLoadGuid as string}
          hiddenElements={hiddenElements}
          positions={positions}
        />
      )}

      {isLoadMode && (
        <>
          {rips &&
            showRips &&
            rips.map(rip => (
              <PositionMeshElement
                key={rip.position_guid}
                data={rip}
                height={guidToCrossSection[rip.position_guid]?.element_cs.shape.height}
                width={guidToCrossSection[rip.position_guid]?.element_cs.shape.width}
                position_type="rip"
                onClick={e => onModelClick?.(e)}
                isSelected={rip.position_guid === selectedElements?.[0]}
                isTarget={highlightedSecondaryIds.has(rip.position_guid)}
                visible={!hiddenElements.has(rip.wall_guid)}
              />
            ))}

          {lintels &&
            showLintels &&
            lintels.map(lintel => (
              <PositionMeshElement
                key={lintel.position_guid}
                data={lintel}
                height={guidToCrossSection[lintel.position_guid]?.element_cs.shape.height}
                width={guidToCrossSection[lintel.position_guid]?.element_cs.shape.width}
                position_type="lintel"
                onClick={e => onModelClick?.(e)}
                isSelected={lintel.position_guid === selectedElements?.[0]}
                isTarget={highlightedSecondaryIds.has(lintel.position_guid)}
                visible={!hiddenElements.has(lintel.wall_guid)}
              />
            ))}
        </>
      )}
    </>
  )
}

export default StructuralPlanningScene

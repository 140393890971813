import React from 'react'
import { createRoot } from 'react-dom/client'
import { QueryClientProvider } from 'react-query'
import { BrowserRouter as Router } from 'react-router-dom'
import axios from 'axios'
import { enableMapSet } from 'immer'
import { closeSnackbar, SnackbarKey, SnackbarProvider } from 'notistack'
import { PostHogProvider } from 'posthog-js/react'
import { QueryParamProvider } from 'use-query-params'
import { ReactRouter6Adapter } from 'use-query-params/adapters/react-router-6'
import { Button, CssBaseline, GlobalStyles } from '@mui/material'
import { ThemeProvider } from '@mui/material/styles'
import '@fontsource/roboto/300.css'
import '@fontsource/roboto/400.css'
import '@fontsource/roboto/500.css'
import '@fontsource/roboto/700.css'
import * as Sentry from '@sentry/react'
import { BrowserTracing } from '@sentry/tracing'
import ErrorFallBackUI from '@ui/feedback/ErrorFallBackUI'
import { AuthenticationProvider } from 'src/components/contexts'
import queryClient from 'src/state/client'
import { theme, globalStyles } from 'src/styles'
import App from './App'
import { appConfig } from './constants'

// tell immer to create a new Set / Map when mutating an existing Set / Map
// within produce(), more context here: https://immerjs.github.io/immer/map-set
enableMapSet()

const { sentryDSN, environment, apiURL, posthogToken, posthogApiHost } = appConfig

const isProductionOrReleaseEnvironment = environment === 'production'

const closeNotificationAction = (key: SnackbarKey) => (
  <Button
    variant="text"
    size="small"
    color="inherit"
    onClick={() => {
      closeSnackbar(key)
    }}
  >
    OK
  </Button>
)

Sentry.init({
  dsn: sentryDSN,
  tunnel: new URL('sentry-tunnel', apiURL).toString(),
  integrations: [new BrowserTracing()],
  environment: environment,
  tracesSampleRate: isProductionOrReleaseEnvironment ? 0.2 : 1.0,
  enabled: import.meta.env.PROD,
  beforeSend: (event, hint) => {
    if (axios.isAxiosError(hint.originalException)) {
      const { response } = hint.originalException
      if (response && response.status && response.status === 422) {
        return null
      }
    }
    return event
  },
})

const container = document.getElementById('root')
const root = createRoot(container as HTMLElement)

root.render(
  <Sentry.ErrorBoundary
    fallback={({ resetError }) => <ErrorFallBackUI resetError={resetError} />}
    showDialog
  >
    <QueryClientProvider client={queryClient}>
      <SnackbarProvider
        autoHideDuration={10000}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center',
        }}
        action={closeNotificationAction}
      >
        <ThemeProvider theme={theme}>
          <CssBaseline />
          <GlobalStyles styles={globalStyles} />
          <Router>
            <QueryParamProvider adapter={ReactRouter6Adapter}>
              <AuthenticationProvider>
                <PostHogProvider
                  // only set apiKey (and therefore enable posthog) when on
                  // production mode
                  apiKey={import.meta.env.PROD ? posthogToken : undefined}
                  options={{
                    api_host: posthogApiHost,
                    disable_session_recording: true,
                  }}
                >
                  <App />
                </PostHogProvider>
              </AuthenticationProvider>
            </QueryParamProvider>
          </Router>
        </ThemeProvider>
      </SnackbarProvider>
    </QueryClientProvider>
  </Sentry.ErrorBoundary>,
)

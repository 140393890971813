import React, { Fragment, ReactElement, useEffect, useMemo, useRef, useState } from 'react'
import { useMutation, useQueryClient } from 'react-query'
import { useParams } from 'react-router-dom'
import { mapValueKey } from '@editorUtils'
import { useSlabBeamToAssembly } from '@hooks'
import { MAX_UTILIZATION_WARNING_THRESHOLD } from '@resultsConfig'
import { useResultsQueryParams } from '@resultsHooks'
import { makeCrossSectionLabel, makeSlabBeamCrossSectionLabel, getElementType } from '@resultsUtils'
import { AxiosError } from 'axios'
import produce from 'immer'
import { filter, findIndex, maxBy, orderBy, remove } from 'lodash-es'
import { useSnackbar } from 'notistack'
import {
  ArrowForward,
  Check,
  Close,
  Edit,
  FilterAltOutlined,
  Warning,
  EditNote,
  Assistant,
  KeyboardArrowUp,
  KeyboardArrowDown,
} from '@mui/icons-material'
import { LoadingButton } from '@mui/lab'
import {
  TableContainer,
  Paper,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  useTheme,
  Tooltip,
  Typography,
  TableSortLabel,
  Stack,
  Popover,
  IconButton,
  TextField,
  Checkbox,
  Button,
  MenuItem,
  ListItemText,
  Collapse,
} from '@mui/material'
import { SaveButton, ToggleButtonDropDown } from '@ui/actions'
import { DialogBase } from '@ui/feedback'
import { UtilizationC90TextIcon, UtilizationTextIcon } from '@ui/icons/misc'
import { Box } from '@ui/structure'
import { useControlStore, useResultsStore, useSystemManagerStore } from '@editorStores'
import { useElementLabel } from '@editorHooks'
import { SingleElementCSForm } from '@editorComponents'
import {
  getChecksForCrossSections,
  getElementCrossSectionAssignment,
  getMemberCheckSettings,
  postStartCalcMemberChecks,
} from '@queries'
import {
  savePositionGrouping,
  saveSingleMemberCheckSettings,
  updateElementCrossSectionAssignment,
} from '@mutations'
import { buildErrorMessage } from 'src/constants'
import {
  getSlabBeamCrossSectionFromAssembly,
  getSlabBeamStepSizeFromAssembly,
} from 'src/utils/assemblies'
import CompressionSettingsForm from './components/CompressionSettingsForm'
import CrossSectionSuggestionForm from './components/CrossSectionSuggestionForm'
import GroupedElementsRow from './components/GroupedElementsRow'
import { filterablePositionTypes } from './constants'

interface Props {
  bundles: MemberPositionBundleWithMaxUtilization[]
  positionType: PositionGroupingType

  isDownloadingExportDocument: boolean
  onClickDownloadExportDocument: () => void
}

/**
 * @deprecated This component is deprecated as soon as the results grid has been tested on production.
 * Please use PositionResultsGrid instead.
 */
const PositionCategoryGroupingList = ({
  bundles,
  positionType,
  isDownloadingExportDocument,
  onClickDownloadExportDocument,
}: Props): ReactElement => {
  const { projectId } = useParams()

  const {
    params: { selectedElement },
    actions: { setIsLocalMode, selectPosition },
  } = useResultsQueryParams()

  const queryClient = useQueryClient()

  const { palette } = useTheme()

  const getLabel = useElementLabel()

  const elementType = getElementType(positionType)

  const [openIndex, setOpenIndex] = useState<number | null>(null)

  const [sortTableBy, setSortBy] = useState<PositionGroupingTableSortCategory>(
    'utilization-without-compression',
  )
  const [sortDescending, setSortDescending] = useState(true)

  const handleSortClick = (source: PositionGroupingTableSortCategory) => {
    setOpenIndex(null)
    sortTableBy === source ? setSortDescending(!sortDescending) : setSortBy(source)
  }

  const setWallRipsPositionGrouping = useResultsStore(state => state.setWallRipsPositionGrouping)
  const setWallLintelsPositionGrouping = useResultsStore(
    state => state.setWallLintelsPositionGrouping,
  )
  const setSlabBeamsPositionGrouping = useResultsStore(state => state.setSlabBeamsPositionGrouping)
  const setRoofSlabBeamsPositionGrouping = useResultsStore(
    state => state.setRoofSlabBeamsPositionGrouping,
  )
  const setBeamsPositionGrouping = useResultsStore(state => state.setBeamsPositionGrouping)
  const setPurlinsPositionGrouping = useResultsStore(state => state.setPurlinsPositionGrouping)
  const setColumnsPositionGrouping = useResultsStore(state => state.setColumnsPositionGrouping)

  const isDrawerExpanded = useControlStore(state => state.isDrawerExpanded)

  const [intermediateBundles, setIntermediateBundles] = useState(bundles)

  const availableStoreys = useMemo(() => {
    const uniqueStoryLabels = new Set(
      intermediateBundles.map(({ representative_position }) => {
        return getLabel(representative_position).split('.')[0]
      }),
    )

    return uniqueStoryLabels
  }, [intermediateBundles])

  const [visibleStoreys, setVisibleStoreys] = useState<Set<string>>(availableStoreys)

  const rowRefs = useRef<HTMLTableRowElement[]>([])

  const { enqueueSnackbar, closeSnackbar } = useSnackbar()
  const elementGroupingSnackbarKey = 'snk-group-elements'

  const structuralChecks = useResultsStore(state => state.structuralChecks)
  const memberCheckSettings = useResultsStore(state => state.memberCheckSettings)
  const setSingleMemberCheckSetting = useResultsStore(state => state.setSingleMemberCheckSetting)

  const elementCrossSectionAssignment = useSystemManagerStore(
    state => state.elementCrossSectionAssignment,
  )
  const setSingleCrossSection = useSystemManagerStore(state => state.setSingleCrossSection)

  const elementCrossSectionAssignmentToGuid = useMemo(
    () => mapValueKey(elementCrossSectionAssignment, 'element_guid'),
    [elementCrossSectionAssignment],
  )

  // MEMOS
  const slabBeamGuidToAssembly = useSlabBeamToAssembly()

  const filteredBundles = useMemo(() => {
    return intermediateBundles.filter(({ representative_position }) => {
      const storey = getLabel(representative_position).split('.')[0]
      return visibleStoreys.has(storey)
    })
  }, [intermediateBundles, visibleStoreys])

  const sortedBundles = useMemo(
    () =>
      orderBy(
        filteredBundles,
        ({
          exported,
          representative_position,
          maxUtilOnlySupportCompression,
          maxUtilWithoutSupportCompression,
          representative_for,
        }) => {
          if (sortTableBy === 'export') return exported
          if (sortTableBy === 'utilization-just-compression')
            return maxUtilOnlySupportCompression?.max_utilization
          if (sortTableBy === 'utilization-without-compression')
            return maxUtilWithoutSupportCompression?.max_utilization
          if (sortTableBy === 'name') return getLabel(representative_position)
          if (sortTableBy === 'representative') return representative_for.length
          const crossSection =
            elementCrossSectionAssignmentToGuid[representative_position]?.element_cs
          if (sortTableBy === 'cross-section') return makeCrossSectionLabel(crossSection)
        },
        sortDescending ? 'desc' : 'asc',
      ),
    [filteredBundles, sortTableBy, sortDescending],
  )

  const supportCompressionChecks: CombinedPositionCheck[] = useMemo(() => {
    return (
      structuralChecks?.filter(
        check =>
          check.check_type === 'SupportCompression' ||
          check.check_type === 'SteelSupportCompression',
      ) || []
    )
  }, [structuralChecks])

  // EFFECTS

  useEffect(() => {
    setIntermediateBundles(bundles)
  }, [bundles])

  // Scroll to selected row
  useEffect(() => {
    if (!selectedElement) return

    const directlySelectedRow = sortedBundles.findIndex(
      bundle => bundle.representative_position === selectedElement,
    )

    if (directlySelectedRow === -1) {
      const selectedRow = sortedBundles.findIndex(bundle =>
        bundle.representative_for.includes(selectedElement),
      )
      if (selectedRow !== -1 && rowRefs.current[selectedRow]) {
        rowRefs.current[selectedRow].scrollIntoView({ behavior: 'smooth', block: 'nearest' })
        setOpenIndex(selectedRow)
      }
    } else {
      const selectedRow = directlySelectedRow
      if (selectedRow !== -1 && rowRefs.current[selectedRow]) {
        rowRefs.current[selectedRow].scrollIntoView({ behavior: 'smooth', block: 'nearest' })
      }
    }
  }, [selectedElement])

  useEffect(
    () => () => {
      handleGroupingModeEnd()
    },
    [],
  )

  // MUTATIONS

  const changeExportedPosition = (position: string, exported: boolean) => {
    const newBundles = produce(state => {
      const index = findIndex(state, { representative_position: position })
      state[index].exported = exported
    }, intermediateBundles)()
    setIntermediateBundles(newBundles)
  }

  const changeCommentOnPosition = (position: string, comment: string) => {
    const newBundles = produce(state => {
      const index = findIndex(state, { representative_position: position })
      state[index].comment = comment
    }, intermediateBundles)()
    setIntermediateBundles(newBundles)
  }

  const getIntermediateBundlesWithUpdatedGroupings = (
    aggregator: string,
    aggregated: string[],
  ): MemberPositionBundleWithMaxUtilization[] => {
    const newBundles = produce(state => {
      aggregated.map(aggregatedGuid => remove(state, ['representative_position', aggregatedGuid]))

      const index = findIndex(state, { representative_position: aggregator })
      state[index].representative_for = aggregated
      state[index].exported = true
    }, intermediateBundles)()
    return newBundles
  }

  const { mutate, isLoading } = useMutation({
    mutationFn: async (data: MemberPositionBundle[]) => {
      return {
        data: await savePositionGrouping.request(projectId as string, positionType, data),
        positionType,
      }
    },
    onSuccess: ({ data, positionType }) => {
      switch (positionType) {
        case 'wall-lintels':
          setWallLintelsPositionGrouping(data)
          break
        case 'wall-rips':
          setWallRipsPositionGrouping(data)
          break
        case 'slab-beams':
          setSlabBeamsPositionGrouping(data)
          break
        case 'roof-slab-beams':
          setRoofSlabBeamsPositionGrouping(data)
          break
        case 'beams':
          setBeamsPositionGrouping(data)
          break
        case 'purlins':
          setPurlinsPositionGrouping(data)
          break
        case 'columns':
          setColumnsPositionGrouping(data)
          break
      }
      enqueueSnackbar('Erfolgreich gespeichert', { variant: 'success' })
    },
    onError: (error: AxiosError) => {
      enqueueSnackbar(buildErrorMessage(error, 'Fehler beim Löschen'), {
        variant: 'error',
      })
    },
  })

  const { mutateAsync: saveMemberSettings, isLoading: isLoadingMemberSettings } = useMutation(
    (data: SettingsOnMember) => saveSingleMemberCheckSettings.request(projectId as string, data),
    {
      onMutate: async (data: SettingsOnMember) => {
        setSingleMemberCheckSetting(data)
      },
      onError: () => {
        enqueueSnackbar(
          'Einstellungen oder Querschnitts-Zuweisung konnten nicht gespeichert werden',
          { variant: 'error' },
        )

        queryClient.invalidateQueries(getMemberCheckSettings.getKey(projectId))
      },
    },
  )

  const { mutateAsync: saveCSAssignment, isLoading: isLoadingCSAssignmentRegularly } = useMutation(
    (data: ElementCSAssignment) => {
      return updateElementCrossSectionAssignment.request(projectId as string, data)
    },
    {
      onMutate: async (data: ElementCSAssignment) => {
        // Update the local state directly so no refetch is required
        setSingleCrossSection(data.element_guid, data.element_cs)
      },
      onError: (error: AxiosError) => {
        enqueueSnackbar(
          buildErrorMessage(error, 'Fehler beim Speichern der Querschnitts-Zuweisung'),
          { variant: 'error' },
        )
        // Refetch onError to ensure local state is in sync with backend again
        queryClient.invalidateQueries(getElementCrossSectionAssignment.getKey(projectId))
        queryClient.invalidateQueries(getChecksForCrossSections.getKey(projectId as string))
      },
    },
  )

  const { mutateAsync: saveCSAssignmentCSOptForm, isLoading: isLoadingCSAssignmentCSOptForm } =
    useMutation(
      (data: ElementCSAssignment) => {
        const newCSAssignment = updateElementCrossSectionAssignment.request(
          projectId as string,
          data,
        )
        return newCSAssignment
      },
      {
        onMutate: async (data: ElementCSAssignment) => {
          // Update the local state directly so no refetch is required
          setSingleCrossSection(data.element_guid, data.element_cs)
        },
        onSuccess: () => {
          const message = '[Backend] Aktualisiere MemberCheckDependency ...'
          enqueueSnackbar(message, { variant: 'info', key: message })
          postStartCalcMemberChecks.request(projectId as string).then(() => {
            closeSnackbar(message)
            enqueueSnackbar('[Backend] MemberCheckDependency aktualisiert.', { variant: 'success' })
          })
        },
        onError: (error: AxiosError) => {
          enqueueSnackbar(
            buildErrorMessage(error, 'Fehler beim Speichern der Querschnitts-Zuweisung'),
            { variant: 'error' },
          )
          // Refetch onError to ensure local state is in sync with backend again
          queryClient.invalidateQueries(getElementCrossSectionAssignment.getKey(projectId))
          queryClient.invalidateQueries(getChecksForCrossSections.getKey(projectId as string))
        },
      },
    )

  const isLoadingCSAssignment = isLoadingCSAssignmentRegularly || isLoadingCSAssignmentCSOptForm

  const [anchorElementForPopover, setAnchorElementForPopover] = useState<{
    anchor: HTMLButtonElement
    guid: string
    checksData?: {
      supportCompressionChecks: SupportCompressionStructuralCheck[] | SteelCompressionCheck[]
      settingsOnMember: TimberCheckSettings | TimberSlabCheckSettings
    }
    source: PopoverClickSource
  } | null>(null)

  const handleClickForCSForm = (event: React.MouseEvent<HTMLButtonElement>, guid: string) => {
    setAnchorElementForPopover({
      anchor: event.currentTarget,
      guid,
      source: 'cross-section',
    })
  }

  const handleClickForSettingsForm = (
    event: React.MouseEvent<HTMLButtonElement>,
    guid: string,
    compressionChecksForPosition: SupportCompressionStructuralCheck[] | SteelCompressionCheck[],
    settingsOnMember: SettingsOnMember,
  ) => {
    // For now, only handle elements with support_configs in the settings
    if (
      settingsOnMember.setting_type !== 'timber' &&
      settingsOnMember.setting_type !== 'timber-slab'
    )
      return

    setAnchorElementForPopover({
      anchor: event.currentTarget,
      guid,
      checksData: {
        supportCompressionChecks: compressionChecksForPosition,
        settingsOnMember,
      },
      source: 'compression-settings',
    })
  }

  const handleCloseForPopover = () => {
    setAnchorElementForPopover(null)
  }

  const [suggestionDialogElement, setSuggestionDialogElement] = useState<string | null>(null)

  const handleClickForCSSuggestionForm = (
    event: React.MouseEvent<HTMLButtonElement>,
    guid: string,
  ) => {
    setSuggestionDialogElement(guid)
  }

  const handleCloseForCSSuggestionForm = () => {
    setSuggestionDialogElement(null)
  }

  const [elementsToGroup, setElementsToGroup] = useState<{
    aggregator: string | null
    aggregated: string[]
  }>({ aggregator: null, aggregated: [] })

  const handleGroupingModeStart = (aggregator: string, aggregated: string[]) => {
    if (elementsToGroup.aggregator !== null) return

    selectPosition(aggregator)
    setElementsToGroup({
      aggregator: aggregator,
      aggregated: aggregated,
    })
    enqueueSnackbar('Wähle die Bauteile aus der Tabelle, die gruppiert werden sollen.', {
      key: elementGroupingSnackbarKey,
      variant: 'info',
      persist: true,
      preventDuplicate: true,
      action: () => (
        <span
          style={{
            color: '#fff',
          }}
        >
          <Button
            variant="outlined"
            size="small"
            color="inherit"
            onClick={() => {
              selectPosition(aggregator)
              handleGroupingModeEnd()
            }}
          >
            Abbrechen
          </Button>
        </span>
      ),
    })
  }

  const handleGroupingModeEnd = () => {
    setElementsToGroup({
      aggregator: null,
      aggregated: [],
    })
    closeSnackbar(elementGroupingSnackbarKey)
  }

  const isSlabBeam = ['slab_beams', 'roof_slab_beams'].includes(elementType)

  const csIsEditable = !isSlabBeam
  const showCSOptimisationButton = !isSlabBeam

  return (
    <Stack direction="column" spacing={2}>
      <Stack direction="row" justifyContent={'space-between'}>
        {filterablePositionTypes.includes(positionType) ? (
          <ToggleButtonDropDown
            Icon={FilterAltOutlined}
            iconColour={'primary'}
            data-cy="filter-grouping-dropdown-button"
            extendTo="right"
          >
            <Box data-cy={'filter-grouping-menu-options'}>
              {[...availableStoreys]
                .sort()
                .reverse()
                .map(storey => (
                  <MenuItem key={storey} disableGutters={true} disableRipple={true}>
                    <ListItemText primary={storey} sx={{ paddingLeft: 2 }} />
                    <Checkbox
                      checked={visibleStoreys.has(storey)}
                      onClick={() => {
                        if (visibleStoreys.has(storey)) {
                          const updatedStoreys = [...visibleStoreys].filter(
                            visibleStorey => storey !== visibleStorey,
                          )
                          setVisibleStoreys(new Set(updatedStoreys))
                        } else {
                          setVisibleStoreys(new Set([...visibleStoreys, storey]))
                        }
                      }}
                      data-cy={`filter-grouping-checkbox-${storey}`}
                    />
                  </MenuItem>
                ))}
            </Box>
          </ToggleButtonDropDown>
        ) : (
          <Box />
        )}
        <Box display="flex" justifyContent="flex-end" mb={3}>
          <LoadingButton
            loading={isDownloadingExportDocument}
            variant="contained"
            size="small"
            onClick={onClickDownloadExportDocument}
            data-cy={`download-${positionType}-btn`}
          >
            Bauteilliste exportieren
          </LoadingButton>
        </Box>
      </Stack>
      <TableContainer component={Paper} sx={{ maxHeight: '55vh' }}>
        <Table stickyHeader size="small" sx={{ '.MuiTableCell-root': { paddingX: 1 } }}>
          <TableHead>
            <TableRow>
              <TableCell></TableCell>
              <TableCell align="left">
                <TableSortLabel
                  active={sortTableBy === 'name'}
                  direction={sortDescending ? 'desc' : 'asc'}
                  onClick={() => handleSortClick('name')}
                  hideSortIcon={sortTableBy !== 'name'}
                  data-cy={'btn-sort-by-name'}
                >
                  Bauteilname
                </TableSortLabel>
              </TableCell>
              <TableCell align="right">
                <Tooltip title="Maximale Ausnutzung ohne Auflagerpressung" placement="top">
                  <TableSortLabel
                    active={sortTableBy === 'utilization-without-compression'}
                    direction={sortDescending ? 'desc' : 'asc'}
                    onClick={() => handleSortClick('utilization-without-compression')}
                    hideSortIcon={sortTableBy !== 'utilization-without-compression'}
                  >
                    <UtilizationTextIcon />
                  </TableSortLabel>
                </Tooltip>
              </TableCell>
              <TableCell align="right">
                <Tooltip title="Maximale Ausnutzung für Auflagerpressung" placement="top">
                  <TableSortLabel
                    active={sortTableBy === 'utilization-just-compression'}
                    direction={sortDescending ? 'desc' : 'asc'}
                    onClick={() => handleSortClick('utilization-just-compression')}
                    hideSortIcon={sortTableBy !== 'utilization-just-compression'}
                  >
                    <UtilizationC90TextIcon />
                  </TableSortLabel>
                </Tooltip>
              </TableCell>
              {isDrawerExpanded && (
                <TableCell>
                  <TableSortLabel
                    active={sortTableBy === 'cross-section'}
                    direction={sortDescending ? 'desc' : 'asc'}
                    onClick={() => handleSortClick('cross-section')}
                    hideSortIcon={sortTableBy !== 'cross-section'}
                  >
                    QS
                  </TableSortLabel>
                </TableCell>
              )}
              {isDrawerExpanded && <TableCell>AP</TableCell>}
              <TableCell>
                <TableSortLabel
                  active={sortTableBy === 'representative'}
                  direction={sortDescending ? 'desc' : 'asc'}
                  onClick={() => handleSortClick('representative')}
                  hideSortIcon={sortTableBy !== 'representative'}
                >
                  repr.
                </TableSortLabel>
              </TableCell>
              <TableCell align="center">
                <TableSortLabel
                  active={sortTableBy === 'export'}
                  direction={sortDescending ? 'desc' : 'asc'}
                  onClick={() => handleSortClick('export')}
                  hideSortIcon={sortTableBy !== 'export'}
                  data-cy="sort-export"
                >
                  export
                </TableSortLabel>
              </TableCell>
              {isDrawerExpanded && <TableCell>Kommentar</TableCell>}
              {isDrawerExpanded && <TableCell>Vorbem.</TableCell>}
              {isDrawerExpanded && showCSOptimisationButton && <TableCell>CS Opt.</TableCell>}
              <TableCell />
            </TableRow>
          </TableHead>
          <TableBody data-cy="position-category-table-body">
            {sortedBundles.map(
              (
                {
                  representative_position,
                  representative_for,
                  exported,
                  maxUtilizationCheck,
                  maxUtilOnlySupportCompression,
                  maxUtilWithoutSupportCompression,
                  comment,
                  extended_comment,
                },
                index,
              ) => {
                let crossSection: CrossSection
                let crossSectionLabel
                if (isSlabBeam) {
                  const assembly = slabBeamGuidToAssembly[representative_position]
                  const stepSize = getSlabBeamStepSizeFromAssembly(assembly)
                  crossSection = getSlabBeamCrossSectionFromAssembly(assembly)
                  crossSectionLabel = makeSlabBeamCrossSectionLabel(crossSection, stepSize)
                } else {
                  crossSection =
                    elementCrossSectionAssignmentToGuid[representative_position]?.element_cs
                  crossSectionLabel = crossSection && makeCrossSectionLabel(crossSection)
                }

                const label = getLabel(representative_position)

                const compressionChecksForPosition = supportCompressionChecks.filter(
                  check => check.element_guid === representative_position,
                ) as SupportCompressionStructuralCheck[] | SteelCompressionCheck[]

                const settingsForMember = memberCheckSettings?.find(
                  settings => settings.element_guid === representative_position,
                )

                const aggregatedElementBundles: AggregatedElementDataSummary[] =
                  representative_for.map(elementGuid => {
                    const elementPositionChecks = filter(structuralChecks, {
                      element_guid: elementGuid,
                    })
                    const maxCheckUtilisation = maxBy(
                      elementPositionChecks,
                      check => check.max_utilization,
                    )

                    let crossSectionLabel
                    if (isSlabBeam) {
                      const assembly = slabBeamGuidToAssembly[elementGuid]
                      const crossSection = getSlabBeamCrossSectionFromAssembly(assembly)
                      const stepSize = getSlabBeamStepSizeFromAssembly(assembly)
                      crossSectionLabel = makeSlabBeamCrossSectionLabel(crossSection, stepSize)
                    } else {
                      const crossSection =
                        elementCrossSectionAssignmentToGuid[elementGuid]?.element_cs
                      crossSectionLabel = crossSection && makeCrossSectionLabel(crossSection)
                    }
                    return {
                      guid: elementGuid,
                      label: getLabel(elementGuid),
                      crossSectionLabel: crossSectionLabel || 'n/a',
                      maxUtilisation: maxCheckUtilisation?.max_utilization,
                    }
                  })

                const overutilisedAggregatedElements = aggregatedElementBundles.filter(bundle => {
                  return (
                    bundle.maxUtilisation !== undefined &&
                    bundle.maxUtilisation > MAX_UTILIZATION_WARNING_THRESHOLD
                  )
                })

                const isSelected = representative_position === selectedElement
                return (
                  <Fragment key={representative_position}>
                    <TableRow
                      ref={el =>
                        // @ts-ignore
                        (rowRefs.current[index] = el)
                      }
                      onClick={() => {
                        selectPosition(representative_position)
                      }}
                      hover
                      selected={isSelected}
                      sx={{
                        '&:last-child td, &:last-child th': { border: 0 },
                        cursor: 'pointer',
                        '.MuiTableCell-root': {
                          color:
                            maxUtilizationCheck &&
                            maxUtilizationCheck?.max_utilization > MAX_UTILIZATION_WARNING_THRESHOLD
                              ? `${palette.error.main}`
                              : undefined,
                          fontWeight: isSelected ? 'bold' : 'normal',
                        },
                        scrollMarginTop: '35px',
                      }}
                    >
                      <TableCell>
                        <Checkbox
                          disabled={
                            elementsToGroup.aggregator === representative_position ||
                            representative_for.length > 0 ||
                            elementsToGroup.aggregator === null
                          }
                          checked={[
                            elementsToGroup.aggregator,
                            ...elementsToGroup.aggregated,
                          ].includes(representative_position)}
                          sx={{ padding: 0 }}
                          onChange={() => {
                            if (elementsToGroup.aggregated.includes(representative_position)) {
                              const newElements = elementsToGroup.aggregated.filter(
                                elementGuid => elementGuid !== representative_position,
                              )
                              setElementsToGroup({
                                aggregator: elementsToGroup.aggregator,
                                aggregated: newElements,
                              })
                            } else {
                              setElementsToGroup({
                                aggregator: elementsToGroup.aggregator,
                                aggregated: [
                                  ...elementsToGroup.aggregated,
                                  representative_position,
                                ],
                              })
                            }
                          }}
                        />
                      </TableCell>
                      <TableCell data-cy={`td-label-${index}`}>{label}</TableCell>
                      <TableCell align="right">
                        <Tooltip title={maxUtilWithoutSupportCompression?.check_type}>
                          <Typography>
                            {maxUtilWithoutSupportCompression?.max_utilization?.toFixed(2) || 'n/a'}
                          </Typography>
                        </Tooltip>
                      </TableCell>
                      <TableCell align="right">
                        <Tooltip title={maxUtilOnlySupportCompression?.check_type}>
                          <Typography>
                            {maxUtilOnlySupportCompression?.max_utilization.toFixed(2) || 'n/a'}
                          </Typography>
                        </Tooltip>
                      </TableCell>
                      {isDrawerExpanded && (
                        <TableCell>
                          {crossSectionLabel}
                          {csIsEditable && (
                            <IconButton
                              size="small"
                              onClick={(e: React.MouseEvent<HTMLButtonElement>) =>
                                handleClickForCSForm(e, representative_position)
                              }
                              data-cy={`btn-open-cs-form-${index}`}
                            >
                              <Edit fontSize="inherit" />
                            </IconButton>
                          )}
                        </TableCell>
                      )}
                      {isDrawerExpanded && settingsForMember && (
                        <TableCell align="center">
                          <IconButton
                            size="small"
                            onClick={(e: React.MouseEvent<HTMLButtonElement>) =>
                              handleClickForSettingsForm(
                                e,
                                representative_position,
                                compressionChecksForPosition,
                                settingsForMember,
                              )
                            }
                            disabled={
                              (settingsForMember.setting_type !== 'timber' &&
                                settingsForMember.setting_type !== 'timber-slab') ||
                              compressionChecksForPosition.length === 0
                            }
                            data-cy={`btn-open-settings-form-${index}`}
                          >
                            <Edit fontSize="inherit" />
                          </IconButton>
                        </TableCell>
                      )}
                      <TableCell
                        align="center"
                        onClick={event => {
                          event.stopPropagation()

                          handleGroupingModeStart(representative_position, representative_for)
                        }}
                      >
                        <Stack direction={'row'}>
                          {overutilisedAggregatedElements.length > 0 && (
                            <Tooltip
                              title={
                                'Overutilised in group: ' +
                                overutilisedAggregatedElements
                                  .map(bundle => getLabel(bundle.guid))
                                  .join(', ')
                              }
                            >
                              <Warning />
                            </Tooltip>
                          )}
                          <Tooltip
                            title={representative_for.map(guid => getLabel(guid)).join(', ')}
                          >
                            <Typography>{representative_for.length}</Typography>
                          </Tooltip>
                          {representative_for.length > 0 && (
                            <IconButton
                              size="small"
                              onClick={event => {
                                event.stopPropagation()
                                if (index === openIndex) setOpenIndex(null)
                                else {
                                  selectPosition(representative_position)
                                  setOpenIndex(index)
                                }
                              }}
                              sx={{ padding: 0 }}
                            >
                              {index === openIndex ? <KeyboardArrowUp /> : <KeyboardArrowDown />}
                            </IconButton>
                          )}
                        </Stack>
                      </TableCell>
                      <TableCell
                        align="center"
                        onClick={e => {
                          e.stopPropagation()
                          changeExportedPosition(representative_position, !exported)
                        }}
                      >
                        {exported ? (
                          <Check color="success" fontSize="small" data-cy="checked-icon" />
                        ) : (
                          <Close color="disabled" fontSize="small" data-cy="unchecked-icon" />
                        )}
                      </TableCell>
                      {isDrawerExpanded && (
                        <TableCell>
                          <Box height="44px" overflow="auto">
                            <TextField
                              inputProps={{
                                style: {
                                  paddingTop: 0,

                                  paddingBottom: 0,
                                },
                              }}
                              fullWidth={true}
                              onChange={event =>
                                changeCommentOnPosition(representative_position, event.target.value)
                              }
                              value={comment}
                              name={`txt-edit-comment-${index}`}
                              data-cy={`txt-edit-comment-${index}`}
                              size="small"
                              multiline={true}
                            />
                          </Box>
                        </TableCell>
                      )}
                      {isDrawerExpanded && (
                        <TableCell align={'center'}>
                          <Tooltip title={extended_comment}>
                            <IconButton
                              size="small"
                              onClick={(e: { stopPropagation: () => void }) => {
                                e.stopPropagation()
                                setIsLocalMode(true, representative_position, 'settings')
                              }}
                              sx={({ palette }) => ({
                                color:
                                  extended_comment && extended_comment.length > 0
                                    ? 'none'
                                    : `${palette.grey[400]}`, // Mimick 'disabled' colour
                              })}
                              data-cy={
                                extended_comment && extended_comment.length > 0
                                  ? `icon-extended-comment-${index}`
                                  : `icon-extended-comment-${index}-disabled`
                              }
                            >
                              <EditNote />
                            </IconButton>
                          </Tooltip>
                        </TableCell>
                      )}
                      {isDrawerExpanded && showCSOptimisationButton && (
                        <TableCell>
                          <IconButton
                            size="small"
                            onClick={(e: React.MouseEvent<HTMLButtonElement>) =>
                              handleClickForCSSuggestionForm(e, representative_position)
                            }
                            data-cy={`btn-open-cs-opt-form-${index}`}
                          >
                            <Assistant fontSize="inherit" />
                          </IconButton>
                        </TableCell>
                      )}
                      <TableCell>
                        <IconButton
                          size="small"
                          onClick={(e: { stopPropagation: () => void }) => {
                            e.stopPropagation()
                            setIsLocalMode(true, representative_position)
                          }}
                          data-cy={
                            selectedElement === representative_position
                              ? 'btn-to-local-active'
                              : undefined
                          }
                        >
                          <ArrowForward fontSize="inherit" />
                        </IconButton>
                      </TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={12}>
                        <Collapse in={openIndex === index} timeout="auto" unmountOnExit>
                          <GroupedElementsRow
                            aggregatorCrossSection={crossSection}
                            aggregatorCrossSectionLabel={crossSectionLabel}
                            aggregatedElementBundles={aggregatedElementBundles}
                            onSaveCrossSectionAssignment={saveCSAssignment}
                            // eslint-disable-next-line @typescript-eslint/no-empty-function
                            onClickUngroupElement={() => {}}
                          />
                        </Collapse>
                      </TableCell>
                    </TableRow>
                  </Fragment>
                )
              },
            )}
          </TableBody>
        </Table>
      </TableContainer>

      <Popover
        open={!!anchorElementForPopover}
        anchorEl={anchorElementForPopover?.anchor}
        onClose={handleCloseForPopover}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
      >
        {anchorElementForPopover && (
          <Box padding={2}>
            {anchorElementForPopover.source === 'cross-section' && (
              <Box width="400px">
                <SingleElementCSForm
                  selectedElement={anchorElementForPopover.guid}
                  elementType={elementType}
                  invalidateResults={false}
                  onSave={() => handleCloseForPopover()} // close popover on save
                />
              </Box>
            )}
            {anchorElementForPopover.source === 'compression-settings' &&
              anchorElementForPopover.checksData && (
                <Box>
                  <CompressionSettingsForm
                    onSaveMemberCheckSettings={saveMemberSettings}
                    onSaveCrossSectionAssignment={saveCSAssignment}
                    handleClose={handleCloseForPopover}
                    isLoading={isLoadingCSAssignment || isLoadingMemberSettings}
                    supportCompressionChecks={
                      anchorElementForPopover.checksData.supportCompressionChecks
                    }
                    checkSettings={anchorElementForPopover.checksData.settingsOnMember}
                    elementType={elementType}
                  />
                </Box>
              )}
          </Box>
        )}
      </Popover>

      {suggestionDialogElement !== null && (
        <DialogBase
          open={!!suggestionDialogElement}
          onClose={handleCloseForCSSuggestionForm}
          dialogProps={{ maxWidth: false }}
          showActions={false}
        >
          <Box width="60vw" height="60vh">
            <CrossSectionSuggestionForm
              elementGuid={suggestionDialogElement}
              elementLabel={getLabel(suggestionDialogElement)}
              elementType={elementType}
              saveCrossSectionAssignment={saveCSAssignmentCSOptForm}
              saveMemberCheckSettings={saveMemberSettings}
              isLoadingAfterSave={isLoadingCSAssignment || isLoadingMemberSettings}
              handleClose={handleCloseForCSSuggestionForm}
            />
          </Box>
          <></>
        </DialogBase>
      )}

      <Box width="100%" display="flex" justifyContent="flex-end">
        <SaveButton
          loading={isLoading}
          onClick={() => {
            if (elementsToGroup.aggregator) {
              const newBundles = getIntermediateBundlesWithUpdatedGroupings(
                elementsToGroup.aggregator,
                elementsToGroup.aggregated,
              )
              mutate(newBundles)
              selectPosition(elementsToGroup.aggregator)
              handleGroupingModeEnd()
            } else {
              mutate(intermediateBundles)
            }
          }}
          data-cy={'btn-submit-position-group'}
        >
          Speichern
        </SaveButton>
      </Box>
    </Stack>
  )
}

export default PositionCategoryGroupingList
